import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/HomeComponent.vue';
import {store} from "../main";
import { VUEX_BUYOUT } from 'js-mp/src/vue/store/buyout/index';
import { MUTATION_TYPES } from 'js-mp/src/vue/store/buyout/_mutation-types';
import { ACTION_TYPES } from 'js-mp/src/vue/store/buyout/_action-types';


Vue.use(VueRouter);


const routes = [{
	path: '/dpd',
	name: 'dpdMap',
	component: () => import('../views/DPDMap.vue')
	},
	{
	path: '/ochrana-osobnich-udaju',
	name: 'privacyPolicy',
	component: () => import('../views/PrivacyPolicy.vue')
	},
	{
		path: '/pravni-informace',
		name: 'legalInformation',
		component: () => import('../views/LegalInformation.vue')
	},
  {
		path: '/svoz-uspech',
		name: 'CollectionSuccess',
		component: Home,
		props: {
			isSuccess: true,
			mainText: 'svoz-uspech'
		}
	},
	{
		path: '/svoz-jiz-objednan',
		name: 'CollectionAlreadyOrdered',
		component: Home,
		props: {
			isSuccess: true,
			mainText: 'svoz-jiz-objednan'
		}
	},
	{
		path: '/svoz-chyba',
		name: 'CollectionError',
		component: Home,
		props: {
			isSuccess: false,
			mainText: 'svoz-chyba'
		}
	},
	{
		path: '/preceneni-prijato',
		name: 'RevaluationSuccess',
		component: Home,
		props: {
			isSuccess: true,
			mainText: 'preceneni-prijato'
		}
	},
	{
		path: '/preceneni-odmitnuto',
		name: 'RevaluationDenied',
		component: Home,
		props: {
			isSuccess: false,
			mainText: 'preceneni-odmitnuto'
		}
	},
	{
		path: '/preceneni-uzavreno',
		name: 'RevaluationClosed',
		component: Home,
		props: {
			isSuccess: false,
			mainText: 'preceneni-uzavreno'
		}
	},
  {
		path: '/predani-zarizeni/:userBuyoutCode',
		name: 'deliveryPicker',
		component: Home,
    props:{
      isOrderDelivery: true
    },
    beforeEnter: (to, from, next) => {
      store.commit(
        `${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_USER_BUYOUT_CODE}`,
        to.params.userBuyoutCode
      );
      next();
		}
	},
	{
		path: '/vykup/:userBuyoutCode',
		name: 'buyout',
		component: Home,
		beforeEnter: (to, from, next) => {
			if (!to.params.userBuyoutCode) next('/');
			if (store.state.userBuyoutCode === to.params.userBuyoutCode) next();
			else {
				store.commit(
					`${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_IS_BUYOUT_ONLINE}`,
					true
				);
				store.commit(
					`${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_IS_REGISTRATION}`,
					false
				);
        store.commit(
					`${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_IS_LOGGED_IN}`,
					false
				);
        store.commit(
					`${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_BUYOUT_LOAD_ERROR}`,
					null
				);
				store.commit(
					`${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_USER_BUYOUT_CODE}`,
					to.params.userBuyoutCode
				);
        store.dispatch(
          `${VUEX_BUYOUT.NAMESPACE}/${ACTION_TYPES.LOAD_STATE_TYPES}`
        );
			}
			next();
		}
	},
	{
		path: '/:category?/:brand?/:model?/:color?/:quality?',
		name: 'home',
		component: Home,
		beforeEnter: (to, from, next) => {
			if (to.params.category && to.params.category === 'vykup') next('/');
			else next();
		}
	}
];


const router = new VueRouter({
	mode: 'history',
	routes
});

export default router;
