<template>
  <div class="buyout__condition">
    <div class="buyout__breadcrumbs semibold">
      <div class="color--gray cursor--pointer" @click="goBackToState()">
        {{ $t('alza.chose_state') }}
      </div>
      <svg class="icon">
        <use href="../../assets/svg/symbol-defs.svg#icon-arrow"/>
      </svg>
      <div>{{ $t('alza.appraisal_detail') }}</div>
    </div>
    <slot name="dynamicInputType"
          :acceptableDeviceConditions="acceptableDeviceConditions"
    >
      <div class="buyout__conditionGrid" :category-type="selectedCategory && selectedCategory.seo_name">
        <new-dynamic-input
          v-for="(input, index) in acceptableDeviceConditions"
          :ref="`input-${input.id}`"
          :key="input.id"
          :input="input"
          :disabled="disabled"
          :index="index + qualityDeviceConditions.length"
          @input="update($event, index + qualityDeviceConditions.length)"
        />
      </div>
    </slot>
    <div
      v-for="(error, index) in _.get(appraisalErrors,'device_condition_choices')"
      :key="index"
    >
      <div>{{ error }}</div>
    </div>
	</div>
</template>

<script>
import {buyout} from "js-mp";
import NewDynamicInput from '__components__/NewDynamicInput/App';
import {GETTER_TYPES as ALZA_BUYOUT_GT} from "__store__/alzaBuyout/_getter-types";
import {MUTATION_TYPES as ALZA_BUYOUT_MT} from "__store__/alzaBuyout/_mutation-types";
import {mapGetters, mapMutations, mapState} from "vuex";
import {VUEX_ALZA} from "__store__/alzaBuyout";
import {VUEX_BUYOUT} from "js-mp/src/vue/store/buyout";

export default {
  name: "NewAppraisalForm",
  extends: buyout.components.AppraisalForm,
  components:{
    NewDynamicInput,
  },
  computed:{
    ...mapGetters(VUEX_ALZA.NAMESPACE, [
      ALZA_BUYOUT_GT.GET_DEVICE_STATE_CHOSEN,
		]),
    ...mapState(VUEX_BUYOUT.NAMESPACE, [
      'selectedCategory',
    ]),
    chosenDeviceState: {
			get() {
				return this[ALZA_BUYOUT_GT.GET_DEVICE_STATE_CHOSEN];
			},
			set(v) {
				this[ALZA_BUYOUT_MT.SET_DEVICE_STATE_CHOSEN](v);
			},
		},
  },
  methods:{
    ...mapMutations(VUEX_ALZA.NAMESPACE, [
      ALZA_BUYOUT_MT.SET_DEVICE_STATE_CHOSEN,
		]),
    async goBackToState(){
      this.chosenDeviceState = false;
      await this.$vuetify.goTo('#buyout');
    }
  }
}
</script>
