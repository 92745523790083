<template>
  <v-form class="contact app__form" ref="contactForm">
    <div class="app__subtitle color--blue">
      {{ $t('alza.contact_form.do_you_have_questions') }} <span class="noWrap">{{ $t('alza.contact_form.write_us') }}</span>
    </div>
    <div class="formItem">
      <v-text-field
        v-model="fullName"
        hide-details="auto"
        solo
        flat
        :rules="[rules.required]"
        :placeholder="$t('alza.contract_form_data.placeholders.full_name')"
      />
      <div class="app__label">
        {{ $t('alza.contact_form.full_name') }}&nbsp; <span class="color--redTone2">*</span>
      </div>
    </div>
    <div class="formItem">
      <v-text-field
        v-model="emailAddress"
        hide-details="auto"
        solo
        flat
        :class="[emailAddress === '@' ? 'has-placeholder' : '']"
        :rules="[rules.required, rules.email]"
        :placeholder="$t('alza.contract_form_data.placeholders.email')"
      />
      <div class="app__label">
        {{ $t('alza.contact_form.e_mail') }}&nbsp; <span class="color--redTone2">*</span>
      </div>
    </div>
    <div class="formItem">
      <phone-field
        v-model="phoneNumber"
        :placeholder="$t('alza.contract_form_data.placeholders.phone_number')"
        :error-messages="phoneError ? phoneError : []"
      />
      <div class="app__label">
        {{ $t('alza.contract_form_data.phone_number') }}&nbsp; <span class="color--redTone2">*</span>
      </div>
    </div>
    <div class="formItem">
      <v-textarea
        v-model="question"
        hide-details="auto"
        solo
        flat
        auto-grow
        :rules="[rules.required]"
        rows="3"
      />
      <div class="app__label">
        {{ $t('alza.contact_form.your_question') }}&nbsp; <span class="color--redTone2">*</span>
      </div>
    </div>
    <v-btn
      class="mbtn mbtn--green"
      :disabled="!fullName || !emailAddress || !phoneNumber || !question"
      small
      plain
      :ripple="false"
      @click="sendEmail"
    >
      {{ $t('alza.contact_form.send_question') }}
    </v-btn>
  </v-form>
</template>

<script>
import PhoneField from "@/components/PhoneField/App";
import {validationRules} from '@/mixins/validation';
import {apiAxios} from "js-mp/src/js/buyout";
import {URLS} from "__store__/alzaBuyout";
import {eventBus} from "@/main";

export default {
  name: "ContactForm",
  components: {PhoneField},
  mixins: [validationRules],
  data() {
    return {
      fullName: '',
      emailAddress: '@',
      phoneNumber: '',
      question: '',
      phoneError: ''
    }
  },
  methods: {
    async sendEmail() {
      try {
        let response = await apiAxios.post(`${URLS.BUYOUT}/${URLS.INFO_EMAIL}`, {
          name: this.fullName,
          customer_email: this.emailAddress,
          phone: this.phoneNumber,
          email_content: this.question
        });
        if (response.status === 200) {
          this.fullName = '';
          this.emailAddress = '@';
          this.phoneNumber = '';
          this.question = '';
          this.phoneError = '';
          this.$refs.contactForm.resetValidation()
          eventBus.$emit('showErrorMessage', {message: `Dotaz odeslán`});
        }
      } catch (err) {
        let msg = err.response.data.phone_number ? err.response.data.phone_number[0] : 'Chyba při zpracovávání požadavku';
        eventBus.$emit('showErrorMessage', {message: msg, is_error: true});
        this.phoneError = err.response.data.phone_number ? err.response.data.phone_number : '';
      }
    },
  }
}
</script>
