<template>
  <div class="faq" id="faq">
    <div class="app__subtitle color--blue">{{ $t('alza.faq') }}</div>
    <v-expansion-panels
      :accordion="true"
      :flat="true"
      :hover="true"
      :multiple="true"
      :tile="true"
    >
      <v-expansion-panel
        v-for="(item, i) in faqs" :key="i"
        :ripple="false"
        :id="`faqItem`+i"
      >
        <v-expansion-panel-header
          class="semibold"
          :hide-actions="true"
        >
          <template v-slot:default="{ open }">
            <div :class="[open ? 'is-opened' : 'is-closed']">
              <svg class="icon fill--blue">
                <use href="../../assets/svg/symbol-defs.svg#icon-arrow"/>
              </svg>
            </div>
            {{ item.question }}
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="faq__message" v-html="$sanitize(item.answer)"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import {buyout} from "js-mp";
import {eventBus} from "@/main";
import {sortByOrder} from "js-mp/src/js/buyout";
import {ACTION_TYPES} from "js-mp/src/vue/store/buyout/_action-types";

export default {
  name: 'FAQ',
  extends: buyout.components.Faq,
  mixins: [buyout.mixins.SanitizeMixin],
  async mounted() {
		if (!this.faqs.length) {
			this.faqs = sortByOrder(await this[ACTION_TYPES.LOAD_FAQS]({identifier: this.faqIdentifier}));
		}
    if (this.$route.query.faq === 'jak-zabalit-zasilku') {
      eventBus.$emit('howToPackFAQ');
    }
	},
}
</script>
