<template>
  <div class="buyout__worth">
    <div class="buyout__breadcrumbs semibold">
      <div>{{ $t('alza.chose_state') }}</div>
    </div>
    <template v-if="appraisalLoadError">
      <h2 class="errorText">{{ appraisalLoadError }}</h2>
    </template>
    <template v-else-if="loadingAppraisal">
      <v-progress-circular
        indeterminate
        color="primary"
        size="80"
      />
    </template>
    <template v-else-if="appraisal">
      <div
        id="deviceCategories"
        class="buyout__worthGrid"
        v-if="qualities"
      >
        <div
          id="deviceState"
          class="buyout__worthItem cursor--pointer"
          v-for="quality in qualities"
          :key="quality.id"
          :class="{'is-active': selectedQuality && selectedQuality.id === quality.id}"
          @click="selectQuality(quality)"
          :data-bosscode="quality.product_code"
        >
          <svg :class="`icon icon--`+quality.quality">
            <use :href="require('../../assets/svg/symbol-defs.svg') + `#icon-${getCategoryIcon}`"/>
          </svg>
          <div class="wrapper">
            <div class="state semibold">
              <div class="category">{{ quality.device_state_description }} <span class="noWrap">({{ quality.device_state_name }})</span></div>
              <div class="price color--redTone2">
                {{ $n(quality.price, "currency") }}
              </div>
            </div>
              <div v-for="param in quality.quality_params" :key="param.id">
                <div
                  class="stateInfo"
                  v-html="capitalizeFirstLetter(param)"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {buyout} from "js-mp";
import {mapMutations, mapState} from "vuex";
import {VUEX_BUYOUT} from "js-mp/src/vue/store/buyout";
import {MUTATION_TYPES} from "js-mp/src/vue/store/buyout/_mutation-types";
import {sleep} from "js-mp/src/js/buyout";

export default {
  name: "NewDeviceState",
  extends: buyout.components.DeviceState,
  computed:{
    ...mapState(VUEX_BUYOUT.NAMESPACE,[
			'loadingAppraisal',
			'appraisal',
			'qualities',
			'appraisalLoadError',
			'selectedCategory'
		]),
    getCategoryIcon(){
      switch(this.selectedCategory?.seo_name) {
        case 'telefony':
          return 'mobile-phone'
        case 'tablety':
          return 'tablet'
        case 'prislusenstvi':
          return 'watch'
        case 'notebooky-a-pc':
          return 'laptop'
        case 'gaming':
          return 'game-console'
        case 'foto-fotoaparaty':
          return 'foto-fotoaparaty'
        case 'foto-objektivy':
          return 'foto-objektivy'
        default:
          return 'mobile-phone'
      }
    },
  },
  methods: {
    ...mapMutations(VUEX_BUYOUT.NAMESPACE, [
      MUTATION_TYPES.SET_DEFAULT_QUALITY_CONDITIONS,
      MUTATION_TYPES.SET_IS_FIRSTLY_LOADED,
    ]),
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async selectQuality(quality) {
      this[MUTATION_TYPES.SET_IS_FIRSTLY_LOADED](false);

      this[MUTATION_TYPES.SET_DEFAULT_QUALITY_CONDITIONS](
        quality.default_device_condition_choices
      );
      await sleep(10);
      this.qualitySelected = quality;
    }
  }
}
</script>
