<template>
  <v-dialog
    v-model="dialog"
    max-width="480px"
    content-class="popup buyoutState"
  >
    <v-card>
      <v-card-title>
        <div @click="dialog = false">
          <svg class="icon icon--22 cursor--pointer">
            <use href="../assets/svg/symbol-defs.svg#icon-times"/>
          </svg>
        </div>
      </v-card-title>
      <v-card-text>
        <div class="buyoutState__content">
          <svg :class="['icon', isSuccess ? 'fill--green' : 'fill--redTone1']">
            <use v-if="isSuccess" href="../assets/svg/symbol-defs.svg#icon-success"/>
            <use v-else href="../assets/svg/symbol-defs.svg#icon-error"/>
          </svg>
          <div :class="['subtitle semibold', isSuccess ? 'color--green' : 'color--redTone1']">
            {{ getMainText }}
          </div>
          <div class="desc text-center" v-html="description"></div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="mbtn mbtn--gray"
          plain
          small
          :depressed="true"
          :ripple="false"
          @click="dialog = false"
        >
          {{ $t('alza.understand') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BuyoutState',
  props: {
    mainText: {
      type: String,
      required: true
    },
    isSuccess: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      dialog: true,
    }
  },
  computed:{
    description() {
      switch (this.mainText) {
        case 'svoz-uspech':
          return this.$t('buyout.BuyoutState.collection_success_description')
        case 'svoz-jiz-objednan':
          return this.$t('buyout.BuyoutState.collection_already_ordered_description') + ' ' + this.$t('buyout.BuyoutState.something_went_wrong_description')
        case 'svoz-chyba':
          return this.$t('buyout.BuyoutState.something_went_wrong_description')
        case 'preceneni-prijato':
          return this.$t('buyout.Revaluation.revaluation_success_description')
        case 'preceneni-odmitnuto':
          return this.$t('buyout.Revaluation.revaluation_denied_description')
        case 'preceneni-uzavreno':
          return this.$t('buyout.Revaluation.revaluation_closed_description')
        default:
          return ''
      }
    },
    getMainText() {
      switch (this.mainText) {
        case 'svoz-uspech':
          return this.$t('buyout.BuyoutState.collection_success')
        case 'svoz-jiz-objednan':
          return this.$t('buyout.BuyoutState.collection_already_ordered')
        case 'svoz-chyba':
          return this.$t('buyout.BuyoutState.something_went_wrong')
        case 'preceneni-prijato':
          return this.$t('buyout.Revaluation.revaluation_success')
        case 'preceneni-odmitnuto':
          return this.$t('buyout.Revaluation.revaluation_denied')
        case 'preceneni-uzavreno':
          return this.$t('buyout.Revaluation.revaluation_closed')
        default:
          return ''
      }
    }
  }
}
</script>
