<template>
  <div class="howItWorks" id="how_it_works">
    <div class="howItWorks__grid">
      <div class="app__subtitle color--blue">{{ $t('console.how_it_works') }}?</div>
      <how-it-works-tile
        v-for="(point, index) in points"
        :key="index"
        :point="point"
      />
    </div>
  </div>
</template>
<script>
import {buyout} from "js-mp";
import HowItWorksTile from "@/components/HowItWorks/Tile";

export default {
  name: 'HowItWorks',
  extends: buyout.components.HowItWorks,
  mixins: [buyout.mixins.SanitizeMixin],
  components: {HowItWorksTile},
}
</script>
