export { state } from './_state';
export { getters } from './_getters';
export { mutations } from './_mutations';

import { state } from './_state';
import { getters } from './_getters';
import { mutations } from './_mutations';

export const VUEX_ALZA = {
	NAMESPACE: 'alzaBuyout'
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
}

export const URLS = {
  INFO_EMAIL: 'send-info-email',
  BUYOUT: 'buyout',
  PICKUP_ADDRESS: 'pickup-address',
}
