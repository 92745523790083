<template>
  <vue-tel-input-vuetify
    :solo="true"
    :flat="true"
    :outlined="true"
    hide-details="auto"
    ref="telInput"
    :error-messages="errorMessages"
    :required="required"
    :rules="telRules"
    default-country="CZ"
    :label="label_"
    :name="name"
    :dense="dense"
    :placeholder="placeholder"
    :value="value"
    v-bind="$attrs"
    autocomplete="tel"
    @input="onInputPhone"
  >
    <template #select-prepend>
      <slot name="select-prepend"/>
    </template>
  </vue-tel-input-vuetify>
</template>

<script>
import {validationRules} from '@/mixins/validation';
import VueTelInputVuetify from "./VueTelInputVuetify/App.vue";
import {mapState} from "vuex";
import {VUEX_BUYOUT} from "js-mp/src/vue/store/buyout";

export default {
  name: 'PhoneField',
  components: {
    VueTelInputVuetify
  },
  mixins: [validationRules],
  //inject: ['form'],
  props: {
    errorMessages: {type: Array, default: () => []},
    value: {type: String, required: true},
    required: {type: Boolean, default: true},
    dense: {type: Boolean, default: false},
    showLabel: {type: Boolean, default: true},
    name: {type: String, default: 'telephone'},
    placeholder: {type: String, default: ''}
  },
  data() {
    return {
      errorsInputPhone: '',
    }
  },
  computed: {
    ...mapState(VUEX_BUYOUT.NAMESPACE, [
      'appraisalErrors',
    ]),
    telRules() {
      return this.required ? [this.rules.required] : [];
    },
    label_() {
      return '';
    },
  },
  created() {
    this.form && this.form.register(this);
  },

  beforeDestroy() {
    this.form && this.form.unregister(this);
  },
  methods: {
    onInputPhone(number, payload) {
      if (this.appraisalErrors['customer_data'] && this.appraisalErrors['customer_data']['phone_number']) {
        this.$delete(this.appraisalErrors['customer_data'], 'phone_number');
      }
      if (number) {
        this.errorsInputPhone = payload.isValid ? '' : this.$t('buyout.contractForm.phone_number');
      } else {
        this.errorsInputPhone = this.$t('Required');
      }
      this.$emit('input', payload.isValid ? payload.number.e164 : payload.number.input);
      this.$emit('errors', [this.errorsInputPhone]);
    },
    validate() {
      return this.errorsInputPhone === '' && this.errorMessages.length === 0;
    },
    reset() {
      this.$refs.telInput.reset();
      this.errorsInputPhone = '';
    },
  },
}
</script>
