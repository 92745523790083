import Vue from 'vue';
import App from './App.vue';
import Toastr from 'toastr';
import './styles/entry.js';
import VueScrollTo from 'vue-scrollto';
import * as Sentry from '@sentry/vue';
import router from './router';
import buyout_store, { VUEX_BUYOUT } from 'js-mp/src/vue/store/buyout/index';
import Vuex from 'vuex';
import { buyout } from 'js-mp';
import Vuetify from 'vuetify/lib';
import VueI18n from 'vue-i18n';
import { overrides } from './language_overrides';
import { version } from '../package.json';
import { BrowserTracing } from "@sentry/tracing";
import alza_store, { VUEX_ALZA } from '__store__/alzaBuyout';
import "./cookie-bar";

Vue.config.productionTip = false;

Toastr.options = {
	position: 'top right',
	showDuration: 1000,
	hideDuration: 1000,
	timeOut: 2500,
	delay: 0,
	closeButton: true,
	progressBar: false
}

Vue.use(VueScrollTo);
Vue.use(Vuex);

if (
	process.env.NODE_ENV === 'production' ||
	process.env.NODE_ENV === 'staging'
) {
	Sentry.init({
		Vue,
		dsn: process.env.VUE_APP_SENTRY_DSN,
		release: 'alza_cz@' + version,
		integrations: [
			new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    })
		],
		tracesSampleRate: process.env.NODE_ENV === 'staging' ? 1.0 : 0.65
	});
}

buyout.store.state.contractForm.customer_data.bank_code = '';
buyout.store.state.contractForm.customer_data.bank_account_full_number = '';
buyout.store.state.contractForm.customer_data.e_mail = '@';

Vue.use(Vuetify);
Vue.use(VueI18n);

const CS = VUEX_ALZA.NAMESPACE;
const NS = VUEX_BUYOUT.NAMESPACE;
let options = {
	modules: {}
}

options.modules[NS] = buyout_store;
options.modules[CS] = alza_store;
export const store = new Vuex.Store(options);
export const eventBus = new Vue();
// override default language settings
const { vuetify, i18n } = buyout.plugins;

for (const lang in overrides) {
	i18n.mergeLocaleMessage(lang, overrides[lang]);
}

new Vue({
	store,
	router,
	vuetify,
	i18n,
	render: h => h(App)
}).$mount('#app');
