<template>
  <v-form class="buyout__form app__form" id="buyout_form" ref="form" v-model="formIsValid">
    <div class="sectionTitle color--blue semibold">
      {{ $t('alza.contract_form_data.device_for_buyout') }}
    </div>
    <div class="formItem formItem--tooltip">
      <v-text-field
        ref="buyout_device_imei"
        name="buyout_device_imei"
        :value="form.buyout_device.imei || ''"
        :error-messages="_.get(appraisalErrors, 'buyout_device.imei')"
        :rules="isCategoryWithImei ? [rules.imei, rules.required] : [rules.required]"
        :placeholder="$t('alza.contract_form_data.placeholders.imei')"
        class="formInput--sn"
        solo
        flat
        hide-details="auto"
        @input="setValue('buyout_device.imei', $event)"
      />
      <div class="app__label">
        <div class="desc">{{ isCategoryWithImei ? $t('buyout.contractForm.imei') : $t('buyout.contractForm.sn') }}&nbsp;<span class="color--redTone2">*</span></div>
        <ToolTipIcon :text="isCategoryWithImei ? $t('buyout.contractForm.imei_help_text') : $t('buyout.contractForm.sn_help_text')" :is-arrow-visible="true"/>
      </div>
    </div>
    <div class="formItem formItem--tooltip">
      <v-text-field
        ref="buyout_device_order_number"
        name="buyout_device_order_number"
        :error-messages="_.get(appraisalErrors, 'buyout_device.order_number')"
        :value="form.buyout_device.order_number || ''"
        :placeholder="$t('alza.contract_form_data.placeholders.new_order_number')"
        :rules="selectedBonus ? [rules.required]: []"
        solo
        flat
        hide-details="auto"
        @input="setValue('buyout_device.order_number', $event)"
      />
      <div class="app__label">
        <div class="desc">{{ $t('alza.contract_form_data.new_device_order_number') }} <span v-if="selectedBonus" class="color--redTone2">*</span> </div>
        <ToolTipIcon :text="$t('alza.contract_form_data.order_number_tooltip')" :is-arrow-visible="true"/>
      </div>
    </div>
    <div class="formItem formItem--tooltip" v-if="selectedBrand?.seo_name !== 'ostatni' ? getDeviceTypes.length > 1 : getDeviceTypes.length">
      <v-autocomplete
        ref="buyout_device_device_type"
        name="buyout_device_device_type"
        :placeholder="$t('alza.contract_form_data.placeholders.select_new_device_type')"
        v-model="selectedDeviceType"
        :rules="[rules.required]"
        :items="getDeviceTypes"
        item-text="name_for_web"
        @change="deviceTypeChange"
        return-object
        solo
        flat
        hide-details="auto"
      />
      <div class="app__label">
        <div class="desc">{{ $t('alza.contract_form_data.new_device_device_type') }} <span class="color--redTone2">*</span> </div>
      </div>
    </div>
    <div class="formItem formItem--tooltip" v-if="selectedBrand?.seo_name !== 'ostatni' ? getDeviceTypes.length > 1 : getDeviceTypes.length">
      <v-text-field
        ref="buyout_device_new_device_imei"
        name="buyout_device_new_device_imei"
        :error-messages="_.get(appraisalErrors, 'buyout_device.new_device_imei')"
        :value="form.buyout_device.new_device_imei || ''"
        :placeholder="$t('alza.contract_form_data.placeholders.imei')"
        :rules="selectedBonus ? [rules.required, rules.imei]: []"
        solo
        flat
        hide-details="auto"
        @change="setValue('buyout_device.new_device_imei', $event)"
      />
      <div class="app__label">
        <div class="desc">{{ $t('alza.contract_form_data.new_device_imei') }} <span v-if="selectedBonus" class="color--redTone2">*</span> </div>
        <ToolTipIcon :text="$t('buyout.contractForm.imei_help_text')" :is-arrow-visible="true"/>
      </div>
    </div>
    <div class="sectionDivider"/>
    <div class="sectionTitle color--blue semibold">
      {{ $t('console.personal_information') }}
    </div>
    <div class="formItem formItem--name">
      <div class="formItem formItem--gcUnset degree">
        <v-text-field
          ref="customer_data_degree_before_name"
          name="customer_data_degree_before_name"
          :value="form.customer_data.degree_before_name || ''"
          :error-messages="_.get(appraisalErrors, 'customer_data.degree_before_name')"
          :placeholder="$t('alza.contract_form_data.placeholders.degree')"
          solo
          flat
          hide-details="auto"
          @input="setValue('customer_data.degree_before_name', $event)"
        />
        <div class="app__label">
          {{ $t('buyout.contractForm.degree_before_name') }}
        </div>
      </div>
      <div class="formItem formItem--gcUnset">
        <v-text-field
          ref="customer_data_first_name"
          name="customer_data_first_name"
          :value="nameAndSurname"
          :error-messages="nameError"
          :placeholder="$t('alza.contract_form_data.placeholders.full_name')"
          :rules="[rules.required]"
          solo
          flat
          hide-details="auto"
          @input="splitFullName($event)"
        />
        <div class="app__label">
          {{ $t('alza.contact_form.full_name') }}&nbsp; <span class="color--redTone2">*</span>
        </div>
      </div>
    </div>
    <div class="formItem formItem--radioGroup">
      <v-radio-group
        ref="customer_data_gender"
        name="customer_data_gender"
        v-model="selectedGender"
        row
        :error-messages="_.get(appraisalErrors, 'customer_data.gender')"
        :rules="[rules.required]"
      >
        <v-radio
          v-for="gender in genders"
          :key="gender.identifier"
          :label="gender.description"
          :value="gender.identifier"
          :ripple="false"
        />
      </v-radio-group>
      <div class="app__label">
        {{ $t('buyout.contractForm.gender') }}&nbsp; <span class="color--redTone2">*</span>
      </div>
    </div>
    <div class="formItem">
      <v-autocomplete
        ref="customer_data_nationality"
        name="customer_data_nationality"
        :value="form.customer_data.nationality.identifier || ''"
        :items="states"
        item-value="identifier"
        :placeholder="$t('alza.contract_form_data.placeholders.nationality')"
        item-text="short_name"
        :rules="[rules.required]"
        :error-messages="_.get(appraisalErrors, 'customer_data.nationality')"
        solo
        flat
        hide-details="auto"
        @input="setNationality($event)"
        attach
      />
      <div class="app__label">
        {{ $t('buyout.contractForm.nationality') }}&nbsp; <span class="color--redTone2">*</span>
      </div>
    </div>
    <div class="formItem">
      <v-text-field
        ref="customer_data_personal_identification_number"
        name="customer_data_personal_identification_number"
        :value="form.customer_data.personal_identification_number || ''"
        :rules="[
          rules.required,
          rules.isPinValid
        ]"
        :error-messages="_.get(appraisalErrors,'customer_data.personal_identification_number')"
        solo
        flat
        hide-details="auto"
        @input="setValue('customer_data.personal_identification_number', $event)"
      />
      <div class="app__label">
        {{ $t('buyout.contractForm.pin_or_birth_date') }}&nbsp; <span class="color--redTone2">*</span>
      </div>
    </div>
    <div class="formItem">
      <v-text-field
        ref="customer_data_place_of_birth"
        name="customer_data_place_of_birth"
        :value="form.customer_data.place_of_birth || ''"
        :error-messages="_.get(appraisalErrors, 'customer_data.place_of_birth')"
        class="formInput--birth"
        :placeholder="$t('alza.contract_form_data.placeholders.place_of_birth')"
        :rules="[rules.required]"
        solo
        flat
        hide-details="auto"
        @input="setValue('customer_data.place_of_birth', $event)"
      />
      <div class="app__label">
        {{ $t('buyout.contractForm.place_of_birth') }}&nbsp; <span class="color--redTone2">*</span>
      </div>
    </div>
    <div class="sectionDivider"/>
    <div class="sectionTitle color--blue semibold">
      {{ $t('alza.contract_form_data.identity_card') }}
    </div>
    <div class="formItem">
      <v-text-field
        ref="customer_data_identity_card_number"
        name="customer_data_identity_card_number"
        :value="form.customer_data.identity_card_number || ''"
        :error-messages="_.get(appraisalErrors, 'customer_data.identity_card_number')"
        :rules="[rules.required]"
        solo
        flat
        hide-details="auto"
        @input="setValue('customer_data.identity_card_number', $event)"
      />
      <div class="app__label">
        {{ $t('alza.contract_form_data.identity_card_number') }}&nbsp; <span class="color--redTone2">*</span>
      </div>
    </div>
    <div class="formItem">
      <v-text-field
        ref="customer_data_identity_released_by"
        name="customer_data_identity_released_by"
        :value="form.customer_data.identity_released_by || ''"
        :placeholder="$t('alza.contract_form_data.placeholders.identity_released_by')"
        :error-messages="_.get(appraisalErrors, 'customer_data.identity_released_by')"
        :rules="[rules.required]"
        solo
        flat
        hide-details="auto"
        @input="setValue('customer_data.identity_released_by', $event)"
      />
      <div class="app__label">
        {{ $t('buyout.contractForm.identity_released_by') }}&nbsp; <span class="color--redTone2">*</span>
      </div>
    </div>
    <div class="formItem">
      <div id="datePicker" class="formInput--datePicker">
        <v-menu
          v-model="isOrderDatepickerMenuVisible"
          :close-on-content-click="false"
          min-width="290"
          left
          offset-y
          attach="#datePicker"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              ref="customer_data_identity_valid_to"
              name="customer_data_identity_valid_to"
              v-model="displayDate"
              :rules="[
                      rules.required,
                      rules.dateIsValid,
                      rules.dateIsNewer
                    ]"
              :error-messages="_.get(appraisalErrors, 'customer_data.identity_valid_to')"
              :placeholder="$t('alza.contract_form_data.placeholders.identity_valid_to')"
              autocomplete="off"
              solo
              flat
              hide-details="auto"
              v-on="on"
              @focus.native="isOrderDatepickerMenuVisible = true"
              @blur="dateOnBlur()"
            >
              <template slot="append">
                <div @click="isOrderDatepickerMenuVisible = true">
                  <svg class="icon fill--blue cursor--pointer">
                    <use href="../../assets/svg/symbol-defs.svg#icon-calendar"/>
                  </svg>
                </div>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            :typeable="true"
            :value="datePickerDate"
            locale="cs"
            no-title
            show-current
            scrollable
            @input="setDataFromDatePicker($event)"
            :allowed-dates="allowedDates"
            @change="isOrderDatepickerMenuVisible = false"
          />
        </v-menu>
      </div>
      <div class="app__label">
        {{ $t('buyout.contractForm.identity_valid_to') }}&nbsp; <span class="color--redTone2">*</span>
      </div>
    </div>
    <div class="sectionDivider"/>
    <div class="sectionTitle color--blue semibold">
      {{ $t('alza.contract_form_data.permanent_residence') }}
    </div>
    <div class="formItem">
      <v-text-field
        ref="customer_data_street"
        name="customer_data_street"
        :value="form.customer_data.street || ''"
        :error-messages="addressError"
        class="formInput--street"
        :placeholder="$t('alza.contract_form_data.placeholders.street')"
        :rules="[rules.required]"
        solo
        flat
        hide-details="auto"
        @input="setAddressValue('customer_data.street', $event)"
      />
      <div class="app__label">
        {{ $t('alza.contract_form_data.street_with_number') }}&nbsp; <span class="color--redTone2">*</span>
      </div>
    </div>
    <div class="formItem formItem--city">
      <div class="formItem formItem--gcUnset">
        <v-text-field
          ref="customer_data_city"
          name="customer_data_city"
          :value="form.customer_data.city || ''"
          :placeholder="$t('alza.contract_form_data.placeholders.city')"
          :error-messages="_.get(appraisalErrors, 'customer_data.city')"
          :rules="[rules.required]"
          solo
          flat
          hide-details="auto"
          @input="setAddressValue('customer_data.city', $event)"
        />
        <div class="app__label">
          {{ $t('buyout.contractForm.city') }}&nbsp; <span class="color--redTone2">*</span>
        </div>
      </div>
      <div class="formItem formItem--gcUnset postcode">
        <v-text-field
          ref="customer_data_postcode"
          name="customer_data_postcode"
          :value="form.customer_data.postcode || ''"
          :placeholder="$t('alza.contract_form_data.placeholders.postcode')"
          :error-messages="_.get(appraisalErrors, 'customer_data.postcode')"
          :rules="[rules.required, rules.postCode]"
          solo
          flat
          hide-details="auto"
          @input="setAddressValue('customer_data.postcode', $event)"
        />
        <div class="app__label">
          {{ $t('buyout.contractForm.postcode') }}&nbsp; <span class="color--redTone2">*</span>
        </div>
      </div>
    </div>
    <div class="formItem">
      <v-autocomplete
        ref="customer_data_country_identifier"
        name="customer_data_country_identifier"
        :value="form.customer_data.country_identifier || ''"
        :placeholder="$t('buyout.contractForm.country')"
        :rules="[rules.required]"
        :items="states"
        item-value="identifier"
        :error-messages="_.get(appraisalErrors, 'customer_data.country_identifier')"
        item-text="short_name"
        solo
        flat
        attach
        hide-details="auto"
        @input="setValue('customer_data.country_identifier', $event)"
      />
      <div class="app__label">
        {{ $t('buyout.contractForm.country') }}&nbsp; <span class="color--redTone2">*</span>
      </div>
    </div>
    <div class="formItem formItem--checkbox">
      <v-checkbox
        v-model="differentPickupAddress"
        :ripple="false"
        hide-details="auto"
        :label="$t('alza.contract_form_data.different_pickup_place')"
      />
    </div>
    <template v-if="differentPickupAddress">
      <div class="formItem">
        <v-text-field
          ref="pickup_address_street"
          name="pickup_address_street"
          :value="pickupAddress.street || ''"
          :error-messages="_.get(appraisalErrors, 'pickup_address.street')"
          class="formInput--street"
          :placeholder="$t('alza.contract_form_data.placeholders.street')"
          :rules="[rules.required]"
          solo
          flat
          hide-details="auto"
          @input="setPickupAddressValue('street', $event)"
        />
        <div class="app__label">
          {{ $t('alza.contract_form_data.street_with_number') }}&nbsp; <span class="color--redTone2">*</span>
        </div>
      </div>
      <div class="formItem formItem--city">
        <div class="formItem formItem--gcUnset">
          <v-text-field
            ref="pickup_address_city"
            name="pickup_address_city"
            :value="pickupAddress.city || ''"
            :placeholder="$t('alza.contract_form_data.placeholders.city')"
            :error-messages="_.get(appraisalErrors, 'pickup_address.city')"
            :rules="[rules.required]"
            solo
            flat
            hide-details="auto"
            @input="setPickupAddressValue('city', $event)"
          />
          <div class="app__label">
            {{ $t('buyout.contractForm.city') }}&nbsp; <span class="color--redTone2">*</span>
          </div>
        </div>
        <div class="formItem formItem--gcUnset postcode">
          <v-text-field
            ref="pickup_address_postcode"
            name="pickup_address_postcode"
            :value="pickupAddress.post_code || ''"
            :placeholder="$t('alza.contract_form_data.placeholders.postcode')"
            :error-messages="_.get(appraisalErrors, 'pickup_address.postcode')"
            :rules="[rules.required, rules.postCode]"
            solo
            flat
            hide-details="auto"
            @input="setPickupAddressValue('post_code', $event)"
          />
          <div class="app__label">
            {{ $t('buyout.contractForm.postcode') }}&nbsp; <span class="color--redTone2">*</span>
          </div>
        </div>
      </div>
    </template>
    <div class="sectionDivider"/>
    <div class="sectionTitle color--blue semibold">
      {{ $t('alza.contract_form_data.contact_info') }}
    </div>
    <div class="formItem">
      <v-text-field
        ref="customer_data_e_mail"
        name="customer_data_e_mail"
        :value="form.customer_data.e_mail || '@'"
        :class="[form.customer_data.e_mail === '@' ? 'formInput--email has-placeholder' : 'formInput--email']"
        :placeholder="$t('alza.contract_form_data.placeholders.email')"
        :rules="[rules.required, rules.email]"
        :error-messages="_.get(appraisalErrors, 'customer_data.e_mail')"
        solo
        flat
        hide-details="auto"
        @input="setValue('customer_data.e_mail', $event)"
      />
      <div class="app__label">
        {{ $t('buyout.contractForm.e_mail') }}&nbsp; <span class="color--redTone2">*</span>
      </div>
    </div>
    <div class="formItem">
      <phone-field
        ref="customer_data_phone_number"
        name="customer_data_phone_number"
        v-model="phonePrefix"
        :placeholder="$t('alza.contract_form_data.placeholders.phone_number')"
        :error-messages="_.get(appraisalErrors, 'customer_data.phone_number')"
      />
      <div class="app__label">
        {{ $t('alza.contract_form_data.phone_number') }}&nbsp; <span class="color--redTone2">*</span>
      </div>
    </div>
    <div class="sectionDivider"/>
    <div class="sectionTitle color--blue semibold">
      {{ $t('alza.contract_form_data.bank_connection') }}
    </div>
    <div class="formItem formItem--bank">
      <v-text-field
        ref="customer_data_bank_account_full_number"
        name="customer_data_bank_account_full_number"
        :value="form.customer_data.bank_account_full_number || ''"
        :placeholder="$t('alza.contract_form_data.placeholders.bank_account')"
        :rules="[rules.required, rules.isBankNumberValid]"
        :error-messages="_.get(appraisalErrors, 'customer_data.bank_account_full_number')"
        solo
        flat
        hide-details="auto"
        @input="setValue('customer_data.bank_account_full_number', $event)"
      />
      <div class="app__label">
        {{ $t('alza.contract_form_data.bank_account_and_code') }}&nbsp; <span class="color--redTone2">*</span>
      </div>
      <div class="slash">/</div>
      <v-text-field
        ref="customer_data_bank_code"
        name="customer_data_bank_code"
        :value="form.customer_data.bank_code || ''"
        :placeholder="$t('alza.contract_form_data.placeholders.bank_code')"
        :rules="[rules.required, rules.isBankCodeValid]"
        :error-messages="_.get(appraisalErrors, 'customer_data.bank_code')"
        solo
        flat
        hide-details="auto"
        @input="setValue('customer_data.bank_code', $event)"
      />
    </div>
    <div class="sectionDivider"/>
  </v-form>
</template>

<script>
import {buyout} from 'js-mp';
import PhoneField from '@/components/PhoneField/App';
import ToolTipIcon from '@/components/ToolTipIcon/App';
import {VUEX_BUYOUT} from 'js-mp/src/vue/store/buyout';
import {VUEX_ALZA} from '__store__/alzaBuyout';
import {validationRules} from '@/mixins/validation';
import {mapState, mapMutations, mapGetters, mapActions} from 'vuex';
import {MUTATION_TYPES as ALZA_MT} from '__store__/alzaBuyout/_mutation-types';
import {GETTER_TYPES} from "js-mp/src/vue/store/buyout/_getter-types";
import {MUTATION_TYPES} from "js-mp/src/vue/store/buyout/_mutation-types";
import {ACTION_TYPES} from "js-mp/src/vue/store/buyout/_action-types";
import {EventBus} from "js-mp/src/vue/event_bus";
import {eventBus} from "@/main";

export default {
  name: "ContractForm",
  components: {PhoneField, ToolTipIcon},
  extends: buyout.components.ContractForm,
  mixins: [validationRules],
  data() {
    return {
      isOrderDatepickerMenuVisible: false,
      outputDate: '',
      displayDate: '',
      pinOrDate: '',
      selectedDeviceType: null,
    }
  },
  async created() {
    if (!this[GETTER_TYPES.CAMPAIGN]) await this[ACTION_TYPES.LOAD_CAMPAIGN]();
		if (!this[GETTER_TYPES.GENDERS].length) this[ACTION_TYPES.LOAD_GENDERS]();
		if (!this[GETTER_TYPES.STATES].length) this[ACTION_TYPES.LOAD_STATES]();
		EventBus.$on('buyoutLoaded', () => {
			this.displayDate = this.formatDate(
				this.form.customer_data.identity_valid_to
			);
		});
    eventBus.$on('resetValidation', () => {
      this.resetFormValidation();
    });
	},
  computed: {
    ...mapState(VUEX_ALZA.NAMESPACE, ['contactFormIsValid']),
    ...mapState(VUEX_BUYOUT.NAMESPACE, [
      'contractForm',
      'selectedCategory',
      'buyoutAdditionalInfo',
      'pickupAddress',
      'appraisalErrors',
      'selectedBonus',
      'selectedQuality',
      'selectedBrand'
    ]),
    ...mapGetters(VUEX_BUYOUT.NAMESPACE, [
      GETTER_TYPES.GET_IS_DIFFERENT_PICKUP_ADDRESS,
      GETTER_TYPES.GENDERS,
      GETTER_TYPES.STATES,
      GETTER_TYPES.CAMPAIGN,
    ]),
    getDeviceTypes(){
      let other_device_type = {
        name_for_web: 'Jiné zařízení'
      }
      if (this.selectedBrand && this.selectedBrand.seo_name){
        let buyout_bonuses = (this[GETTER_TYPES.CAMPAIGN]?.buyout_bonuses ?? []).filter(
          bonus => bonus.type_categories.includes(this.selectedCategory.seo_name)
          && bonus.minimum_buyout_price <= this.selectedQuality?.price
        );
        if (this.selectedBrand.seo_name !== 'ostatni'){
          if (!buyout_bonuses.find(bonus => {return bonus.name_for_web === other_device_type.name_for_web})) buyout_bonuses.push(other_device_type);
        }
        return buyout_bonuses;
      }
      return [];
    },
    datePickerDate() {
      return this.isDateIsoValid(this.form.customer_data.identity_valid_to)
        ? this.form.customer_data.identity_valid_to
        : '';
    },
    phonePrefix: {
      get: function () {
        return this.form.customer_data.phone_number || '';
      },
      set: function (val) {
        this.setFormValue('customer_data.phone_number', val);
      }
    },
    formIsValid: {
      get: function () {
        return this.contactFormIsValid;
      },
      set: function (val) {
        this[ALZA_MT.SET_CONTACT_FORM_IS_VALID](val);
      }
    },
    differentPickupAddress: {
      get() {
        return this[GETTER_TYPES.GET_IS_DIFFERENT_PICKUP_ADDRESS];
      },
      set(v) {
        this[MUTATION_TYPES.SET_IS_DIFFERENT_PICKUP_ADDRESS](v);
      }
    },
    isCategoryWithImei(){
      return this.selectedCategory.seo_name === 'telefony';
    },
    nameAndSurname(){
      let firstName = this.form.customer_data.first_name || ''
      let lastName = this.form.customer_data.last_name || ''
      if (firstName || lastName){
        return firstName + ' ' + lastName
      }
      return ''
    },
    nameError: {
      cache: false,
      get(){
        if (this._.get(this.appraisalErrors, 'customer_data.first_name') || this._.get(this.appraisalErrors, 'customer_data.last_name')) {
        return this.$t('alza.contract_form_data.name_error')
        }
        return null
      }
    },
    addressError: {
      cache: false,
      get(){
        if (this._.get(this.appraisalErrors, 'pickup_address.x_address')) {
        return this.$t('alza.contract_form_data.address_error')
        }
        return null
      }
    },
    selectedGender: {
      get: function () {
        return this.form.customer_data.gender || null;
      },
      set: function (val) {
        this.setFormValue('customer_data.gender', val);
      }
    }
  },
  methods: {
    ...mapActions(VUEX_BUYOUT.NAMESPACE, [
      ACTION_TYPES.LOAD_GENDERS,
      ACTION_TYPES.LOAD_STATES,
      ACTION_TYPES.LOAD_CAMPAIGN
    ]),
    ...mapMutations(VUEX_ALZA.NAMESPACE, [
      ALZA_MT.SET_CONTACT_FORM_IS_VALID,
    ]),
    ...mapMutations(VUEX_BUYOUT.NAMESPACE, [
      MUTATION_TYPES.SET_IS_DIFFERENT_PICKUP_ADDRESS,
      MUTATION_TYPES.SET_SELECTED_BONUS,
      MUTATION_TYPES.SET_NEW_DEVICE_IMEI
    ]),
    deviceTypeChange(){
      // Fix for autofill
      if(!this.contractForm?.buyout_device?.new_device_imei)  this.$refs.buyout_device_new_device_imei.$emit("change", this.$refs.buyout_device_new_device_imei.internalValue);
      if(this.selectedDeviceType && this.selectedDeviceType.bonus_value && this.selectedDeviceType.bonus_value > 0) {
        this[MUTATION_TYPES.SET_SELECTED_BONUS](this.selectedDeviceType);
      }
      else this[MUTATION_TYPES.SET_SELECTED_BONUS](null);
    },
    setDataFromDatePicker(value) {
      this.setFormValue('customer_data.identity_valid_to', value);
    },
    dateOnBlur() {
      let date = this.parseDate(this.displayDate);
      if (!date) date = this.displayDate;
      this.setFormValue('customer_data.identity_valid_to', date);
    },
    formatDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split('-');
			if (!year || !month || !day) return date;
			return `${day}.${month}.${year}`;
		},
    isDateIsoValid(date) {
      if (!date) return false;
      const re = /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/;
      return re.test(date);
    },
    parseDate(date) {
      if (!date) {
        return null;
      }

      date = date.split(' ').join('');
      let [day, month, year] = [null, null, null];
      if (date.split('.').length === 3) {
        [day, month, year] = date.split('.');
        if (year.length <= 2) year = parseInt(year) + 2000;
      } else if (date.split('-').length === 3) {
        [year, month, day] = date.split('-');
        if (day > 31) {
          [day, month, year] = date.split('-');
        }
      }
      if (!day || !month || !year) {
        return null;
      }

      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    allowedDates(date) {
      return new Date(date).getTime() >= Date.now();
    },
    resetFormValidation() {
      if(this.$refs?.form) this.$refs.form.resetValidation();
    },
    setValue(formKey, formValue) {
      let keys = formKey.split(".")
      this.setFormValue(formKey, formValue);
      if (this.appraisalErrors[keys[0]] && this.appraisalErrors[keys[0]][keys[1]]) {
        this.$delete(this.appraisalErrors[keys[0]], keys[1]);
      }
    },
    setAddressValue(formKey, formValue) {
      this.setFormValue(formKey, formValue);
      if (this.appraisalErrors['pickup_address']) {
        this.$delete(this.appraisalErrors['pickup_address']);
      }
    },
    setNationality(value) {
      let state = this.states.find(state => state.identifier === value);
      this.setValue('customer_data.nationality.identifier', value);
      this.setValue('customer_data.nationality.label', state.name);
      this.setValue('customer_data.nationality.value', state.id);
    },
    splitFullName(fullname){
      let trimmedFullName = fullname.trim()
      let lastName = trimmedFullName.substring(trimmedFullName.lastIndexOf(' ') + 1, trimmedFullName.length).trim();
      let firstName = trimmedFullName.slice(0, -lastName.length).trim();
      this.setValue('customer_data.first_name', firstName)
      this.setValue('customer_data.last_name', lastName)
    },
  },
  watch: {
    displayDate(){
      this.setValue('customer_data.identity_valid_to', this.displayDate);
    },
		appraisalErrors() {
			const keys = Object.keys(this.appraisalErrors).sort()
			if (keys.length > 0) {
				const key = keys[0];
        if (key === 'pickup_address') {
          this.$vuetify.goTo(this.$refs.customer_data_street, {
            offset: 100
          });
          return;
        }
				const subkey = Object.keys(this.appraisalErrors[key])[0];
				this.$vuetify.goTo(this.$refs[`${key}_${subkey}`], {
					offset: 100
				});
			}
		},
    "form.customer_data.nationality"() {
      if(this.$refs.customer_data_personal_identification_number)
        this.$refs.customer_data_personal_identification_number.validate()
    },
    selectedQuality(newVal) {
      if(newVal?.price && this.selectedBonus?.minimum_buyout_price > newVal.price){
        this.selectedDeviceType = null;
        this[MUTATION_TYPES.SET_SELECTED_BONUS](null);
      }
    }
	}
}
</script>
