import { MUTATION_TYPES } from './_mutation-types';

export const mutations = {
	[MUTATION_TYPES.SET_CONTACT_FORM_IS_VALID](state, val) {
		state.contactFormIsValid = val;
	},
  [MUTATION_TYPES.SET_DEVICE_CHOSEN](state, val) {
		state.deviceChosen = val;
	},
  [MUTATION_TYPES.SET_DEVICE_APPRAISED](state, val) {
		state.deviceAppraised = val;
	},
  [MUTATION_TYPES.SET_DEVICE_STATE_CHOSEN](state, val) {
		state.deviceStateChosen = val;
	},
}
