<template>
  <div>
    <header id="pageHeader" class="header">
      <a href="https://www.alza.cz/" aria-label="Alza" target="_self" rel="noopener">
        <svg class="icon">
          <use href="../assets/svg/symbol-defs.svg#icon-logo"/>
        </svg>
      </a>
    </header>
    <main class="content">
      <div class="divider"></div>
      <buyout-state v-if="openDialog" :is-success="isSuccess" :main-text="mainText"/>
      <delivery-picker v-if="isOrderDelivery"/>
      <main-claim/>
      <how-it-works/>
      <device-buyout id="buyout" />
      <faq id="faq" ref="faqRef"/>
      <contact-form id="contact_us"/>
    </main>
    <footer class="footer color--white">
      <div class="app__subtitle">Sledujte nás na sociálních sítích</div>
      <div class="footer__desc text-center">
        <div>#wearealza</div>
        <div>soutěže</div>
        <div>novinky</div>
        <div>inspirace</div>
        <div>technologie</div>
      </div>
      <div class="footer__icons">
        <a href="https://www.facebook.com/alza.cz/" aria-label="Facebook" target="_blank" rel="noopener">
          <div class="icon icon--facebook">
            <svg class="fill--white">
              <use href="../assets/svg/symbol-defs.svg#icon-facebook"/>
            </svg>
          </div>
        </a>
        <a href="https://twitter.com/Alzacz" aria-label="Twitter" target="_blank" rel="noopener">
          <div class="icon icon--twitter">
            <svg class="fill--white">
              <use href="../assets/svg/symbol-defs.svg#icon-twitter"/>
            </svg>
          </div>
        </a>
        <a href="https://www.youtube.com/channel/UC0wtqzWrBA5gL4Jr_QIQTgg" aria-label="YouTube" target="_blank" rel="noopener">
          <div class="icon icon--youtube">
            <svg>
              <use href="../assets/svg/symbol-defs.svg#icon-youtube"/>
            </svg>
          </div>
        </a>
        <a href="https://www.instagram.com/alza_czsk/" aria-label="Instagram" target="_blank" rel="noopener">
          <div class="icon icon--instagram">
            <svg class="fill--white">
              <use href="../assets/svg/symbol-defs.svg#icon-instagram"/>
            </svg>
          </div>
        </a>
      </div>
    </footer>
  </div>
</template>

<script>
import MainClaim from '@/components/MainClaim/App';
import HowItWorks from '@/components/HowItWorks/App';
import DeviceBuyout from '@/components/DeviceBuyout/App';
import Faq from '@/components/FAQ/App';
import ContactForm from '@/components/ContactForm/App';
import {ACTION_TYPES} from "js-mp/src/vue/store/buyout/_action-types";
import {mapActions, mapState} from "vuex";
import {VUEX_BUYOUT} from "js-mp/src/vue/store/buyout";
import {VUEX_ALZA} from '__store__/alzaBuyout';
import {eventBus} from "@/main";
import BuyoutState from "./BuyoutState";
import DeliveryPicker from "./DeliveryPicker";
import {sleep} from "js-mp/src/js/buyout";


export default {
  name: 'HomeComponent',
  components: {
    MainClaim,
    HowItWorks,
    DeviceBuyout,
    Faq,
    ContactForm,
    BuyoutState,
    DeliveryPicker
  },
  props: {
    mainText: {
      type: String,
      default: ''
    },
    isSuccess: {
      type: Boolean,
      default: true
    },
    isOrderDelivery:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(VUEX_BUYOUT.NAMESPACE, [
      'isRegistration',
      'isLoggedIn',
      `userBuyoutCode`
    ]),
    ...mapState(VUEX_ALZA.NAMESPACE, [
      'default_return_page'
    ]),
    openDialog() {
      return !!this.mainText;
    }
  },
  async created() {
    try{
      sessionStorage.setItem("returnURL", this.checkReturnURL(document.referrer));
    }
    catch(SecurityError){
      // eslint-disable-line
    }
    if (history.scrollRestoration) {
      history.scrollRestoration = 'manual';
    }
    this[ACTION_TYPES.LOAD_CAMPAIGN]();
    eventBus.$on('howToPackFAQ', () => {
      this.howToPackFaq();
    });
  },
  async mounted() {
    let chromeAgent = window.navigator.userAgent.indexOf("Chrome") > -1;
    let safariAgent = window.navigator.userAgent.indexOf("Safari") > -1;
    if ((chromeAgent) && (safariAgent)) safariAgent = false;
    if (safariAgent && this.$route.hash === '#faq'){
      await sleep(50);
      await this.$vuetify.goTo('#faq', { offset: 72 });
    }
    else if (chromeAgent && this.$route.hash === '#faq'){
      await sleep(500);
      await this.$vuetify.goTo('#faq', { offset: 72 });
    }

    if (safariAgent && this.$route.hash === '#contact_us'){
      await sleep(50);
      await this.$vuetify.goTo('#contact_us', { offset: 72 });
    }
    else if (chromeAgent && this.$route.hash === '#contact_us'){
      await sleep(500);
      await this.$vuetify.goTo('#contact_us', { offset: 72 });
    }
  },
  methods: {
    ...mapActions(VUEX_BUYOUT.NAMESPACE, [
      ACTION_TYPES.LOAD_CAMPAIGN,
    ]),
    checkReturnURL(page_url) {
     let substring = 'alza.cz'
     if (page_url.includes(substring)) {
       return page_url
     }
     return this.default_return_page
    },
    async howToPackFaq(){
      let faq_array = this.$refs.faqRef.$children[0].$children;
      if (faq_array) {
        faq_array[faq_array.length - 1].isActive = true
        await this.$vuetify.goTo('#faqItem'+(faq_array.length - 1).toString(), { offset: 115 });
      }
    }
  },
}
</script>
