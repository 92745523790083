<template>
  <div>
    <v-app class="app">
      <transition name="fade-in-out" appear>
        <router-view></router-view>
      </transition>

    </v-app>
    <error-message />
  </div>
</template>

<script>
import ErrorMessage from '@/components/ErrorMessage/App';
import {loadGTM} from "./cookie-bar";

export default {
	name: 'App',
  components: {
    ErrorMessage
  },
  created() {
    loadGTM("GTM-WB7T9N8", "dataLayerOLC");
    loadGTM("GTM-5K9ZS4R");
  }
}
</script>
