<template>
  <div id="main_claim" class="mainClaim">
    <div class="mainClaim__desc">
      <h1 class="app__title">
        <span class="color--darkBlue">{{ $t('alza.main_title_first') }},</span>
        <span class="color--blue">{{ $t('alza.main_title_second') }}</span>
      </h1>
      <div class="mainClaim__subtitle">
        Za výhodnou cenu od vás <span class="semibold">odkoupíme starý telefon, hodinky, tablet, notebook nebo herní konzoli</span> a
        vy si můžete pořídit nové zařízení.
      </div>
      <v-btn
        :class="['mbtn mbtn--green', isMobile ? 'v-size--small w100' : 'v-size--x-large']"
        plain
        :depressed="true"
        :ripple="false"
        @click="$vuetify.goTo('#buyout')"
      >
        Zjistit cenu
      </v-btn>
    </div>
    <picture>
      <source media="(min-width: 851px)" srcset="../../assets/img/header-bg-alza-vykup-cz.webp" type="image/webp">
      <source media="(max-width: 850px)" srcset="../../assets/img/header-bg-mobile-alza-vykup-cz.webp" type="image/webp" width="850" height="716">
      <source media="(max-width: 850px)" srcset="../../assets/img/header-bg-mobile-alza-vykup-cz.jpg" width="850" height="716">
      <img
        src="../../assets/img/header-bg-alza-vykup-cz.jpg"
        class="img--mainClaim mw--unset"
        width="1310"
        height="520"
        alt="Alza výkup - Prodej starý, bude na nový"
      >
    </picture>
    <picture>
      <source srcset="../../assets/img/alzak-alza-vykup-cz.webp" type="image/webp">
      <img
        src="../../assets/img/alzak-alza-vykup-cz.png"
        class="img--character mw--unset"
        width="815"
        height="496"
        alt="Alza výkup - Alzák"
      >
    </picture>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
  name: "MainClaim",
  data() {
		return {
			isMobile: false
		}
	},
  created() {
		window.addEventListener('resize', debounce(this.onResize, 250), {
			passive: false
		});
		this.onResize();
	},
	beforeDestroy() {
		window.removeEventListener('resize', debounce(this.onResize, 250));
	},
  methods: {
    onResize() {
			this.isMobile = window.innerWidth <= 850;
		},
	},
}
</script>
