<template>
  <div>
    <div class="buyout__summarySticky"></div>
    <div class="buyout__summary" id="summary">
      <div :class="['buyout__summaryItem', (canNotBeBuyout && chosenDeviceState) || (!canNotBeBuyout && chosenDeviceState) ? '' : 'buyout__summaryItem--name']">
        <img
          :src="buildAbsoluteURL(selectedColor.image ? selectedColor.image : notFoundImage)"
          :alt="`phone_image_${selectedColor.name}`"
          @error="imageNotFound"
        />
        <div class="name semibold">
          <div class="color--darkBlue">{{ selectedColor.name }}</div>
          <div class="changeItem cursor--pointer semibold color--lightBlue" @click="changeModel()">
            <div class="desc">{{ $t('alza.change') }}</div>
            <svg class="icon fill--blue">
              <use href="../../assets/svg/symbol-defs.svg#icon-pencil"/>
            </svg>
          </div>
        </div>
      </div>
      <div
        v-if="canNotBeBuyout && chosenDeviceState"
        class="buyout__summaryItem buyout__summaryItem--error"
      >
        <svg class="icon fill--redTone1">
          <use href="../../assets/svg/symbol-defs.svg#icon-error"/>
        </svg>
        <div class="wrapper">
          <div class="semibold color--redTone1">
            {{ $t('alza.buyout_cannot_finish') }}
          </div>
          <div class="color--gray">
            {{ $t('alza.quality_error') }}
          </div>
        </div>
      </div>
      <template v-if="!canNotBeBuyout && chosenDeviceState">
        <div class="buyout__summaryItem buyout__summaryItem--state">
          <svg class="icon fill--darkBlue">
            <use :href="require('../../assets/svg/symbol-defs.svg') + `#icon-${getCategoryIcon}`"/>
          </svg>
          <div class="wrapper">
            <div class="color--gray noWrap">
              {{ $t('alza.chose_state') }}
            </div>
            <div class="semibold noWrap" v-if="selectedQuality">
              {{ selectedQuality.device_state_name }}
            </div>
          </div>
        </div>
        <div class="buyout__summaryItem buyout__summaryItem--price">
          <svg class="icon fill--darkBlue">
            <use href="../../assets/svg/symbol-defs.svg#icon-cashback"/>
          </svg>
          <div class="wrapper">
            <div class="color--gray">
              Výkupní cena
            </div>
            <div class="price semibold color--redTone2" v-if="selectedQuality">
              {{ $n(this.finalBuyoutPrice, "currency") }}
              <ToolTipIcon
                v-if="(getTotalPriceCorrection || selectedBonus) && !this.displayMinimalPriceHint"
                :text="priceTextInfo()"
                :is-arrow-visible="true"
                class="lalala"
              />
              <ToolTipIcon
                v-if="this.displayMinimalPriceHint"
                :text="$t('buyout.info_minimal_buyout_price')"
                :is-arrow-visible="true"
              />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {VUEX_BUYOUT} from 'js-mp/src/vue/store/buyout';
import {mapGetters, mapMutations, mapState} from 'vuex';
import {GETTER_TYPES} from "js-mp/src/vue/store/buyout/_getter-types";
import {MUTATION_TYPES} from "js-mp/src/vue/store/buyout/_mutation-types";
import {VUEX_ALZA} from "__store__/alzaBuyout";
import {MUTATION_TYPES as ALZA_BUYOUT_MT} from "__store__/alzaBuyout/_mutation-types";
import {config} from "js-mp/src/vue/config/buyout";
import {buildImageUrl} from "js-mp/src/js/buyout";
import {GETTER_TYPES as ALZA_BUYOUT_GT} from "__store__/alzaBuyout/_getter-types";
import SanitizeMixin from "js-mp/src/vue/mixins/buyout/sanitize";
import debounce from 'lodash/debounce';
import ToolTipIcon from "__components__/ToolTipIcon/App";

export default {
  name: "NewDeviceSummary",
  components: {ToolTipIcon},
  mixins: [SanitizeMixin],
  data() {
    return {
      sidebarTopPosition: 0,
      sidebarHeight: 0,
      faqTopPosition: 0,
      absPosition: 0,
      minimal_buyout_price: config.minimal_buyout_price,
    }
  },
  computed: {
    ...mapState(VUEX_BUYOUT.NAMESPACE, [
      'selectedColor',
      'selectedQuality',
      'selectedModel',
      'canNotBeBuyout',
      'selectedCategory',
      'selectedBonus'
    ]),
    ...mapGetters(VUEX_BUYOUT.NAMESPACE, [
      GETTER_TYPES.SELECTED_QUALITY,
      GETTER_TYPES.APPRAISAL,
      GETTER_TYPES.SELECTED_BONUS,
      GETTER_TYPES.GET_TOTAL_PRICE_CORRECTION,
    ]),
    ...mapGetters(VUEX_ALZA.NAMESPACE, [
      ALZA_BUYOUT_GT.GET_DEVICE_APPRAISED,
      ALZA_BUYOUT_GT.GET_DEVICE_CHOSEN,
      ALZA_BUYOUT_GT.GET_DEVICE_STATE_CHOSEN
    ]),
    chosenDeviceState: {
      get() {
        return this[ALZA_BUYOUT_GT.GET_DEVICE_STATE_CHOSEN];
      },
      set(v) {
        this[ALZA_BUYOUT_MT.SET_DEVICE_STATE_CHOSEN](v);
      }
    },
    qualitySelected: {
      get() {
        return this[GETTER_TYPES.SELECTED_QUALITY];
      },
      set(v) {
        this[MUTATION_TYPES.SET_SELECTED_QUALITY](v);
      }
    },
    chosenDevice: {
      get() {
        return this[ALZA_BUYOUT_GT.GET_DEVICE_CHOSEN];
      },
      set(v) {
        this[ALZA_BUYOUT_MT.SET_DEVICE_CHOSEN](v);
      }
    },
    appraisedDevice: {
      get() {
        return this[ALZA_BUYOUT_GT.GET_DEVICE_APPRAISED];
      },
      set(v) {
        this[ALZA_BUYOUT_MT.SET_DEVICE_APPRAISED](v);
      }
    },
    notFoundImage() {
      return config.not_found_image
    },
    currency() {
      return this.$sanitize(config.currency);
    },
    buyoutPrice(){
      if(!this.selectedQuality)
        return
      let buyout_price = this.selectedQuality.price + this.getTotalPriceCorrection;
       if(this.selectedBonus)
        buyout_price += this.selectedBonus.bonus_value;
      return buyout_price;
    },
    displayMinimalPriceHint(){
      return this.buyoutPrice < this.minimal_buyout_price;
    },
    finalBuyoutPrice(){
      return Math.max(this.buyoutPrice, this.minimal_buyout_price);
    },
    getCategoryIcon(){
      switch(this.selectedCategory?.seo_name) {
        case 'telefony':
          return 'mobile-phone'
        case 'tablety':
          return 'tablet'
        case 'prislusenstvi':
          return 'watch'
        case 'notebooky-a-pc':
          return 'laptop'
        case 'gaming':
          return 'game-console'
        case 'foto-fotoaparaty':
          return 'foto-fotoaparaty'
        case 'foto-objektivy':
          return 'foto-objektivy'
        default:
          return 'mobile-phone'
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.fixedSidebar);
    window.addEventListener('resize', debounce(this.fixedSidebar, 25), {
      passive: false
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', debounce(this.fixedSidebar, 25));
    window.removeEventListener('scroll', this.fixedSidebar);
  },
  methods: {
    ...mapMutations(VUEX_BUYOUT.NAMESPACE, [
      MUTATION_TYPES.SET_SELECTED_QUALITY,
      MUTATION_TYPES.SET_APPRAISAL
    ]),
    ...mapMutations(VUEX_ALZA.NAMESPACE, [
      ALZA_BUYOUT_MT.SET_DEVICE_CHOSEN,
      ALZA_BUYOUT_MT.SET_DEVICE_APPRAISED,
      ALZA_BUYOUT_MT.SET_DEVICE_STATE_CHOSEN
    ]),
    async changeModel() {
      this.qualitySelected = null
      this.chosenDeviceState = null
      this.chosenDevice = false
      this.appraisedDevice = false
      await this.$vuetify.goTo('#buyout');
    },
    buildAbsoluteURL(endpoint) {
      return buildImageUrl(endpoint);
    },
    imageNotFound(event) {
      event.target.src = buildImageUrl(this.notFoundImage);
    },
    fixedSidebar() {
      const sidebar = document.querySelector('.buyout__summary');
      const sidebarSticky = document.querySelector('.buyout__summarySticky');
      if(!sidebar || !sidebarSticky)
        return
      this.sidebarTopPosition = sidebarSticky.getBoundingClientRect().top + window.scrollY - 72;
      this.sidebarHeight = sidebar.getBoundingClientRect().height;

      const faq = document.querySelector('.faq');
      if(!faq)
        return
      this.faqTopPosition = faq.getBoundingClientRect().top + window.scrollY - 142;
      this.absPosition = this.faqTopPosition - this.sidebarHeight;

      if ((document.documentElement.scrollTop > this.sidebarTopPosition) && (document.documentElement.scrollTop < this.absPosition)) {
        sidebar.classList.add('is-sticky');
        sidebar.classList.remove('is-absolute');
        sidebar.style.maxWidth = `${sidebarSticky.getBoundingClientRect().width}px`;
        sidebarSticky.style.height = '70px';
      }
      else if ((document.documentElement.scrollTop > this.sidebarTopPosition) && (document.documentElement.scrollTop > this.absPosition)) {
        sidebar.classList.remove('is-sticky');
        sidebar.classList.add('is-absolute');
        sidebar.style.maxWidth = `${sidebarSticky.getBoundingClientRect().width}px`;
        sidebarSticky.style.height = '70px';
      }
      else {
        sidebar.classList.remove('is-sticky');
        sidebar.classList.remove('is-absolute');
        sidebarSticky.style.height = '0';
      }
    },
    priceTextInfo() {
      const devicePrice = this.$n(this.selectedQuality.price, "currency");
      const devicePriceCorrection = this.$n(Math.abs(this.getTotalPriceCorrection), "currency");
      const devicePriceFinal = this.$n(this.finalBuyoutPrice, "currency");

      if (this.selectedBonus && this.getTotalPriceCorrection) {
        return "" +
          "<div class='priceInfo'>" +
            "<div class='color--gray'>Zařízení</div>" + "<div class='noWrap bold text-right'>" + devicePrice + "</div>" +
            "<div class='color--gray'>Srážky</div>" + "<div class='noWrap bold text-right'>- " + devicePriceCorrection + "</div>" +
            "<div class='color--gray'>Bonus</div>" + "<div class='noWrap bold text-right'>+ " + this.$n(this.selectedBonus.bonus_value , "currency") + "</div>" +
            "<div class='divider--tooltip'></div>" +
            "<div class='color--gray'>Výkupní cena</div>" + "<div class='noWrap bold text-right color--redTone2'>" + devicePriceFinal + "</div>" +
          "</div>";
      }

      if (this.selectedBonus) {
        return "" +
          "<div class='priceInfo'>" +
            "<div class='color--gray'>Zařízení</div>" + "<div class='noWrap bold text-right'>" + devicePrice + "</div>" +
            "<div class='color--gray'>Bonus</div>" + "<div class='noWrap bold text-right'>+ " + this.$n(this.selectedBonus.bonus_value , "currency") + "</div>" +
            "<div class='divider--tooltip'></div>" +
            "<div class='color--gray'>Výkupní cena</div>" + "<div class='noWrap bold text-right color--redTone2'>" + devicePriceFinal + "</div>" +
          "</div>";
      }

      if (this.getTotalPriceCorrection) {
        return "" +
          "<div class='priceInfo'>" +
            "<div class='color--gray'>Zařízení</div>" + "<div class='noWrap bold text-right'>" + devicePrice + "</div>" +
            "<div class='color--gray'>Srážky</div>" + "<div class='noWrap bold text-right'>- " + devicePriceCorrection + "</div>" +
            "<div class='divider--tooltip'></div>" +
            "<div class='color--gray'>Výkupní cena</div>" + "<div class='noWrap bold text-right color--redTone2'>" + devicePriceFinal + "</div>" +
          "</div>";
      }
    }
  }
}
</script>
