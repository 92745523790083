<template>
	<div>
    <div class="buyout__conditionItem" v-if="input.type === 'boolean'">
      <div class="desc">
        <div class="icon" :data-name="input.name"/>
        <div class="name">{{ input.name }}</div>
        <ToolTipIcon :text="input.help_text" :is-arrow-visible="true" :is-text="true"/>
      </div>
      <v-radio-group
        v-model="selected"
        @change="update"
        :column="false"
        mandatory
        :error-messages="infoMessages"
        hide-details="auto"
      >
        <v-radio
          v-for="(choice, index) in input.choices"
          :key="index"
          :label="choice.label"
          :value="choice.value"
          :name="slugify(input.name)"
          :ripple="false"
          :disabled="disabled"
        />
      </v-radio-group>
    </div>
    <div class="buyout__conditionItem" v-if="input.type === 'list'">
      <div class="desc">
        <div class="icon" :data-name="input.name"/>
        <div class="name">{{ input.name }}</div>
        <ToolTipIcon :text="input.help_text" :is-arrow-visible="true" :is-text="true"/>
      </div>
      <v-select
        v-model="selected"
        item-text="label"
        item-value="value"
        @change="update"
        :items="input.choices"
        dense
        rounded
        attach
        :menu-props="{ closeOnClick: true, offsetY: true }"
        :name="slugify(input.name)"
        :disabled="disabled"
        :error-messages="infoMessages"
        hide-details="auto"
      />
    </div>
  </div>
</template>

<script>
import {buyout} from 'js-mp';
import ToolTipIcon from "__components__/ToolTipIcon/App";

export default {
	name: "NewDynamicInput",
	components: {ToolTipIcon},
  extends: buyout.components.DynamicInput,
}
</script>
