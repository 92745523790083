<template>
  <div class="iconBox text-center">
    <svg class="icon fill--blue" :class="point.icon">
      <use :href="iconImagePath"/>
    </svg>
    <div class="iconBox__order semibold color--white">
      {{ point.order }}
    </div>
    <div class="iconBox__title bold">
      {{ point.title }}
    </div>
    <slot name="text" :text="point.description">
      <div class="iconBox__desc" v-html="$sanitize(point.description)"></div>
    </slot>
  </div>
</template>

<script>
import {buyout} from 'js-mp';

export default {
  name: 'HowItWorksTile',
  mixins: [buyout.mixins.SanitizeMixin],
  extends: buyout.components.HowItWorks.components.Tile,
  computed: {
    iconImagePath() {
      return (
        require('../../assets/svg/symbol-defs.svg') + `#${this.point.icon}`
      );
    },
  },
}
</script>
