<template>
  <v-menu
    v-model="isVisible"
    :close-on-content-click="false"
    :min-width="280"
    :max-width="280"
    :nudge-top="15"
    :nudge-left="125"
    offset-y
    top
    content-class="toolTipIcon__content"
  >
    <template v-slot:activator="{ on }">
      <div
        class="toolTipIcon__iconWrapper cursor--pointer"
        v-on="on"
        @click="showTooltip"
      >
        <template v-if="!isText">
          <svg class="icon fill--green">
            <use href="../../assets/svg/symbol-defs.svg#icon-info"/>
          </svg>
        </template>
        <template v-else>
          <div class="desc color--gray">Info</div>
        </template>
      </div>
    </template>
    <v-card flat>
      <div
        class="toolTipIcon__close cursor--pointer"
        @click="isVisible = false"
      >
        <svg class="icon">
          <use href="../../assets/svg/symbol-defs.svg#icon-times"/>
        </svg>
      </div>
      <div
        :id="`tooltip-text-${_uid}`"
        class="toolTipIcon__text"
        v-html="text"
      />
      <div
        v-if="isArrowVisible"
        class="arrow"
        :style="tooltipArrowStyles"
      />
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "ToolTipIcon",
  props: {
    text: {type: String, required: false, default: "Test"},
    isArrowVisible: {type: Boolean, default: false},
    isText: {type: Boolean, default: false},
  },
  data() {
    return {
      isVisible: false,
      xCoords: 0,
    }
  },
  computed: {
    tooltipArrowStyles() {
      return {left: `${this.xCoords}px`}
    },
  },
  methods: {
    show(v) {
      this.isVisible = v;
    },
    showTooltip(event) {
      this.$nextTick(() => {
        setTimeout(() => {
          const infoElLeft = event.target.getBoundingClientRect().left;
          const textLeft = document.getElementById(`tooltip-text-${this._uid}`).getBoundingClientRect().left;
          this.xCoords = infoElLeft - textLeft + 2;
        }, 100);
      });
    },
  },
}
</script>
