<template>
  <div class="app__snack-wrapper">
    <v-snackbar class="app__snack" :color="color" v-model="snackbar" right top timeout="6000">
      {{ message }}
      <template #action="{ attrs }">
        <svg
          class="icon cursor--pointer"
          v-bind="attrs"
          @click="snackbar = false"
        >
          <use href="../../assets/svg/symbol-defs.svg#icon-times"/>
        </svg>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {eventBus} from "@/main";

export default {
  name: 'ErrorMessage',
  data() {
    return {
      snackbar: false,
      message: null,
      is_error: false
    }
  },
  created() {
    eventBus.$on('showErrorMessage', ({message, is_error}) => {
      this.message = message;
      this.is_error = is_error;
      this.snackbar = true;
    });
  },
  computed: {
    color: function () {
      if (this.is_error) return 'is-error';
      else return 'is-success';
    }
  }
}
</script>

<style scoped></style>
