export function loadGTM(gtmId, dataLayerName = "dataLayer") {
  window[dataLayerName] = window[dataLayerName] || [];

  function gtag(...args) {
    window[dataLayerName].push(args);
  }
  // Improve ad click measurement quality (optional)
  gtag("set", "url_passthrough", true);
  // Further redact your ads data (optional)
  gtag("set", "ads_data_redaction", true);
  // eslint-disable-next-line func-names
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      "gtm.start":
        new Date().getTime(),
      event: "gtm.js",
    });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s); const
      dl = l !== "dataLayer" ? `&l=${l}` : "";
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", dataLayerName, gtmId);

  const noScriptGTM = document.createElement("noscript");
  noScriptGTM.innerHTML = `<noscript>
      <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
              height="0" width="0" style="display:none;visibility:hidden"></iframe>
    </noscript>`;
  document.body.prepend(noScriptGTM);
}
