import { GETTER_TYPES } from './_getter-types';
import { buyout } from 'js-mp';

export const getters = {
	[GETTER_TYPES.GET_BUYOUT_PAYLOAD]() {

		let buyout_device = buyout.store.state.contractForm.buyout_device;

    let isDifPickupAddress = buyout.store.state.isDifferentPickupAddress
    let address = buyout.store.state.pickupAddress;
    if (!isDifPickupAddress)
      address = buyout.store.state.contractForm.customer_data;

		return {
			user_buyout_code: '',
			appraisal_version: buyout.store.state.appraisal.version,
			device_identifier: buyout.store.state.selectedColor.seo_name,
			device_type: buyout.store.state.selectedCategory.seo_name,
			note: buyout_device.note,
			buyout_device: buyout_device,
      buyout_bonus: buyout.store.state.selectedBonus,
			customer_data: buyout.store.state.contractForm.customer_data,
			partner: buyout.store.state.selectedPartner,
			consent_to_marketing: buyout.store.state.consentToMarketing,
			pickup_address: {
					street: address.street,
					city: address.city,
					postcode: isDifPickupAddress ? address.post_code : address.postcode,
					country_identifier: address.country_identifier
				},
		}
	},
  [GETTER_TYPES.GET_DEVICE_CHOSEN](state) {
		return state.deviceChosen;
	},
  [GETTER_TYPES.GET_DEVICE_APPRAISED](state) {
		return state.deviceAppraised;
	},
  [GETTER_TYPES.GET_DEVICE_STATE_CHOSEN](state) {
		return state.deviceStateChosen;
	},
  [GETTER_TYPES.GET_CONTACT_FORM_IS_VALID](state){
    return state.contactFormIsValid;
  }
}
