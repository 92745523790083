<template>
  <div id="device_finder" class="buyout__picker">
    <div class="stepTitle bold color--darkBlue">
      {{ $t('buyout.search_for_device') }}
    </div>
    <div class="app__label">
      {{ $t('buyout.deviceFinder.chose_type') }}
    </div>
    <div
      id="categorySelector"
      class="buyout__devices"
      v-if="!loadingCategories"
    >
      <v-form id="deviceFinderForm">
        <div
          class="buyout__device cursor--pointer"
          v-for="category in categories"
          :key="category.pk"
          :class="{'is-active': selectedCategory && selectedCategory.pk === category.pk}"
          @click="selectCategory(category)"
        >
          <svg :class="['icon', 'icon--' + category.seo_name, 'fill--blue']">
            <!-- TODO: icon path -->
            <use :href="iconImagePath(category)"/>
            <!-- !TODO: icon path -->
          </svg>
          <div class="semibold">
            {{ category.name }}
          </div>
        </div>
        <div class="formItem formItem--1x5"></div>
        <div class="formItem formItem--1x2" v-if="selectedCategory">
          <v-autocomplete
            name="deviceBrand"
            item-text="name"
            item-value="pk"
            :items="brands"
            :disabled="!brands.length || !selectedCategory || isSelectDisabled"
            :loading="loadingBrands"
            :placeholder="$t('alza.choose')"
            hide-details="auto"
            solo
            flat
            clearable
            persistent-placeholder
            v-model="selectedBrand"
            return-object
            @change="brandChange"
          />
          <div class="app__label">{{ $t('buyout.deviceFinder.chose_brand') }}</div>
        </div>
        <div class="formItem formItem--1x3" v-if="selectedCategory">
          <v-autocomplete
            item-text="name"
            item-value="pk"
            :items="models"
            v-model="selectedModel"
            :disabled="!models.length || !selectedBrand || isSelectDisabled"
            :loading="loadingModels"
            :placeholder="$t('alza.choose')"
            hide-details="auto"
            solo
            flat
            clearable
            persistent-placeholder
            @change="modelChange"
            return-object
          />
          <div class="app__label">{{ $t('buyout.deviceFinder.chose_model') }}</div>
        </div>
      </v-form>
    </div>
    <div class="app__label" v-if="selectedModel && displayImages">
      {{ $t('alza.deviceFinder.chose_color') }}
    </div>
    <div
      id="deviceColors"
      class="buyout__devices deviceColors"
      v-if="selectedModel && displayImages"
    >
      <div
        class="buyout__device cursor--pointer"
        v-for="color in colors"
        :key="color.pk"
        :class="{'is-active': selectedColor && selectedColor.pk === color.pk}"
        @click="selectColor(color)"
      >
        <div class="img">
          <img
            :src="buildAbsoluteURL(color.image ? color.image : notFoundImage)"
            :alt="`phone_image_${color.color.name}`"
            @error="imageNotFound"
          />
        </div>
        <div class="capitalize semibold">
          {{ color.color.name }}
        </div>
      </div>
    </div>
    <div id="continue_btn" class="stepperBtns">
      <v-btn
        class="mbtn mbtn--green"
        plain
        small
        :depressed="true"
        :ripple="false"
        :disabled="!selectedCategory || !selectedModel || !selectedColor"
        @click="continueToDeviceState"
      >
        {{ $t('alza.continue') }}
        <svg class="icon icon--right fill--white">
          <use href="../../assets/svg/symbol-defs.svg#icon-arrow"/>
        </svg>
      </v-btn>
    </div>
  </div>
</template>

<script>
import {buyout} from "js-mp";
import {sleep} from "js-mp/src/js/buyout";
import {mapMutations, mapActions, mapGetters} from "vuex";
import {VUEX_ALZA} from "__store__/alzaBuyout";
import {MUTATION_TYPES} from "__store__/alzaBuyout/_mutation-types";
import {MUTATION_TYPES as BUYOUT_MUTATION_TYPES} from "js-mp/src/vue/store/buyout/_mutation-types";
import {VUEX_BUYOUT} from "js-mp/src/vue/store/buyout";
import {ACTION_TYPES} from "js-mp/src/vue/store/buyout/_action-types";
import {GETTER_TYPES as ALZA_BUYOUT_GT} from "__store__/alzaBuyout/_getter-types";


export default {
  name: "NewDeviceFinder",
  extends: buyout.components.DeviceFinder,
  computed: {
    ...mapGetters(VUEX_ALZA.NAMESPACE, [
      ALZA_BUYOUT_GT.GET_DEVICE_CHOSEN,
		]),
    chosenDevice: {
      get() {
				return this[ALZA_BUYOUT_GT.GET_DEVICE_CHOSEN];
			},
      set(v) {
        this[MUTATION_TYPES.SET_DEVICE_CHOSEN](v);
      }
    },
    iconImagePath() {
      return category => (
        require('../../assets/svg/symbol-defs.svg') + `#icon-${category.seo_name}`
      );
    },
  },
  methods: {
    ...mapActions(VUEX_BUYOUT.NAMESPACE, [
			ACTION_TYPES.LOAD_APPRAISAL
		]),
    ...mapMutations(VUEX_BUYOUT.NAMESPACE, [
			BUYOUT_MUTATION_TYPES.SET_USER_BUYOUT_CODE,
      BUYOUT_MUTATION_TYPES.SET_IS_FIRSTLY_LOADED
		]),
    ...mapMutations(VUEX_ALZA.NAMESPACE, [
      MUTATION_TYPES.SET_DEVICE_CHOSEN,
    ]),
    async selectCategory(category) {
      this.selectedCategory = category;
      if (this.userBuyoutCode) this[BUYOUT_MUTATION_TYPES.SET_USER_BUYOUT_CODE](null);
      await sleep(10);
      await this.categoryChange()
      await this.$vuetify.goTo('#device_finder', { offset: 80 });
    },
    async selectColor(color) {
      this[BUYOUT_MUTATION_TYPES.SET_IS_FIRSTLY_LOADED](true);  // So that the quality is not automatically preselected the first time you select a device colour.
      const device_finder = document.querySelector('#deviceColors');
      if(device_finder) {
        let deviceFinderHeight = device_finder.getBoundingClientRect().height;
        await this.$vuetify.goTo('#continue_btn', {offset: deviceFinderHeight});
      }
      await sleep(10);
      await this.selectProduct(color);
    },
    async continueToDeviceState(){
      this.chosenDevice = true
      await this.$vuetify.goTo('#buyout');
    }
  }
}
</script>
